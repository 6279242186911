import Layout from "../components/layout"
import Seo from "../components/seo"
import { Col, Container, Row } from "react-bootstrap"
import React from "react"

const CookiePolicy = () => (
  <Layout pageInfo={{
    pageName: "cookies",
    pageTitle: "Cookie Policy",
    bannerTitle: "website-header-03.png",
  }}>
    <Seo title="Cookie Policy"/>

    <Container>
      <section className="container" id="cookie-policy">
        <Row>
          <Col>
          </Col>
        </Row>
      </section>
    </Container>
  </Layout>
)

export default CookiePolicy

